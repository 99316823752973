import React from "react";
import Application from "@uBehaviour/application";
import Modal from "@cComponents/modal";
import Typeform from "./typeform";
import Button from "@cComponents/button";
import T from "@uBehaviour/i18n";

import "./manager.css";

const CampaignFactory = ({ type, options, onSubmit }) => {
  switch(type){
    case "typeform": return (<Typeform id={ options.id } onSubmit={ onSubmit }/>);
    default: throw new Error(`Unknow campaign type "${type}"`)
  }
}

const AskAgain = ({ decline, accept}) => (
  <div className="bs-campaign-manager-askAgain">
    <div className="bs-campaign-manager-askAgain-label"><T>campaign_manager_askAgain_label</T></div>
    <div className="bs-campaign-manager-askAgain-actions">
      <Button.Text onClick={ decline }><T>campaign_manager_askAgain_decline</T></Button.Text>
      <Button.Text onClick={ accept }><T>campaign_manager_askAgain_accept</T></Button.Text>
    </div>
  </div>
);

class Manager extends React.Component{
  state = {
    displayConfirm : false
  }
  hasCampagn(){
    return this.props.campaign.has();
  }
  onSubmit = (close, campaignId, responseId) => {
    this._current = null;
    this.props.campaign.inform(campaignId, responseId)
      .then(close);
  }
  onClose = () => {
    if(this._current){
      this.setState({ displayConfirm: true });
    }
  }
  _neverAskAgain(close){
    this.props.campaign.inform(this._current._id);
    close();
  }
  _askAgain(close){
    close();
  }
  get firstCampaign(){
    return this.props.campaign.current[0];
  }
  render(){
    if(!this.hasCampagn()){
      return null;
    }
    //Récupération dans le contexte de la fonction de la première campagne afin de ne pas faire boguer le rerendu de la modal
    //Au moment où l'on valide l'envoi de la participation à la campagne
    //Car si il n'y a plus de campagne alors le rerendu de la modale essayer de récupérer la première campagne qui n'existe plus
    const firstCampaign = this.firstCampaign;

    return !this.state.displayConfirm
      ? (
        <Modal.Show key={this.firstCampaign._id} style={{ width: "90vw", height: "90vh" }} close={ this.onClose } onOpen={() => { this._current = this.firstCampaign }}>
        { close => (
          <CampaignFactory type={ firstCampaign.type } options={ firstCampaign.options } onSubmit={ this.onSubmit.bind(this, close, firstCampaign._id) } />
        )}
        </Modal.Show>
      )
      : (
        <Modal.Show style={{ width: "30vw", height: "20vh" }} userCanClose={ false }>
        {(close) => (
          <AskAgain decline={ this._neverAskAgain.bind(this, close) } accept={ this._askAgain.bind(this, close) } />
        )}
        </Modal.Show>
      );
  }
}

export default Application.Service.forward(["campaign"], Manager);